import React, { useState } from "react";
import "./JobDescription.css";

const JobDescriptions = () => {
  const jobDescriptionsList = [
    "Led technology selection and rollout, focusing on organizational planning and supplier service-level agreements.",
    "Managed project teams in an Agile environment, realizing success through application of SDLC methodologies and exceptional leadership skills.",
    "Microsoft Endpoint Configuration Manager as a management platform for Windows endpoints providing inventory, software distribution, operating system imaging, settings and security management.",
    "System Center Operations Manager a cross platform data center monitoring system for operating systems and hypervisors availability, performance, configuration and security.",
    "Outlined work plans, determined resources, wrote timelines, and generated initial budgets as part of project scope determination.",
    "Developed project management roadmap to define project objectives, timelines, and milestones.",
    "Led and assisted technical upgrade projects for clients by working and coordinating with consultants and developers for integrations.",
    "Assisted in the development of long-term strategies to grow departments and resources.",
    "Guided organizational technology strategy and roadmaps.",
    "Adjusted project plans to account for dynamic targets, staffing changes, and operational specifications.",
    "Demonstrated familiarity with the latest hardware, software, and networking technology.",
    // "Created and terminated user accounts from start to finish in business applications.",
    // "Built, repaired, and installed computer-related hardware based on deployment procedures.",
    "Managed network and system performance, conducting troubleshooting, security patching, and maintenance.",
    // "Customized and repaired technology based on staff requests.",
    "Partnered with project team members to identify and quickly address problems.",
    "Coordinated IT operations activities to deliver smooth flow of daily business needs.",
    // "Oversaw daily performance of computer systems and immediately responded to technical issues to keep network up and running.",
    "Oversaw IT department operations and training.",
    // "Updated customers and senior leaders on progress and roadblocks.",
    "Communicated with executive team and CEO to maximize development efficiencies and resolve technology issues.",
    "Interacted and negotiated with vendors, outsourcers, and contractors to secure products and services.",
    "Understood key product management roadmap objectives and requirements to develop product technology strategy.",
    "Coordinated technical training and personal development classes for staff members.",
    // "Conferred with executives to advise and plan for short-term and long-term IT system upgrade needs.",
    "Wrote policy, procedure, and manuals governing internal IT use.",
    "Oversaw a large portfolio of projects to support teams, report progress, and influence positive outcomes for key stakeholders.",
    "Led and managed staff of IT team members engaged in administering complex IT infrastructure.",
    "Designed, documented, and executed system upgrades and patch management using update manager.",
    "Developed project plans identifying key issues, approaches, and performance metrics.",
    "Proposed and implemented system enhancements to improve performance and reliability of storage environments.",
    "Organized internal audits and led efforts to determine and remedy deficiencies.",
    "Monitored contracts and service level agreements to identify potential risks and implement mitigation actions to protect development processes from unforeseen delays and costs.",
    "Developed and initiated projects, managed costs, and monitored performance.",
    "Met project deadlines without sacrificing build quality or workplace safety.",
    "Maintained relationships with material vendors to lower costs and diversify capabilities.",
    // "Updated operational methods and oversaw accounting procedures for improved efficiency.",
    "Managed projects from procurement to commission.",
    "Maintained compliance with regulatory parameters in complex projects with overlapping policies.",
    // "Facilitated communication between internal personnel, contractors, and vendors for project cohesion.",
    // "Provided fiduciary oversight to remain within budget constraints, recommending project adjustments where necessary.",
    "Made decisions independently and collaboratively on multi-related challenges.",
    "Accelerated progress by continually reviewing performance, budgets, and project timelines.",
    "Identified plans and resources required to meet project goals and objectives.",
    "Orchestrated projects within strict timeframes and budget constraints by solving complex problems and working closely with senior leaders.",
    "Drove team success through shared vision and recognition of quality performance.",
    "Achieved project deadlines by coordinating with contractors to manage performance.",
    "Planned, designed, and scheduled phases for large projects.",
    "Provided detailed product orders based on project drawings and technical specifications.",
    "Maintained clear communication with precise technical writing to document and inform critical processes.",
    "Provided detailed technical and operational direction in project challenges, consistently meeting deliverables according to deadlines.",
    "Led project teams through design, construction, and launch processes.",
    "Modified and directed project plans to meet organizational needs.",
  ];

  const [visibleCards, setVisibleCards] = useState(4);
  const [newCards, setNewCards] = useState([]);

  const handleReadMore = () => {
    const newCount = visibleCards + 4;
    const addedCards = jobDescriptionsList
      .slice(visibleCards, newCount)
      .map((_, i) => visibleCards + i);

    setNewCards(addedCards);
    setVisibleCards(newCount);

    setTimeout(() => setNewCards([]), 1000);
  };

  const handleShowLess = () => {
    setVisibleCards(4);
    setNewCards([]);
  };

  return (
    <div id="jobDescription" className="container section p-3 p-md-5 mb-5">
      <p className="text-center mb-2 wow fadeInUp mt-5 mb-5 wow fadeInUp">
        <span className="bg-primary text-dark px-2">Job Description</span>
      </p>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
        {jobDescriptionsList
          .slice(0, visibleCards)
          .map((description, index) => (
            <div
              key={index}
              className={`col wow fadeInUp ${
                newCards.includes(index) ? "fade-in" : ""
              }`}
            >
              <div className="card h-100 shadow-sm hover-shadow">
                <div className="card-body d-flex align-items-start">
                  <div
                    className="rounded-circle bg-warning me-3 flex-shrink-0"
                    style={{ width: "16px", height: "16px", marginTop: "6px" }}
                  ></div>
                  <p className="card-text text-muted">{description}</p>
                </div>
              </div>
            </div>
          ))}
      </div>

      {visibleCards < jobDescriptionsList.length && (
        <div className="text-center mt-5">
          <button className="btn btn-primary me-2" onClick={handleReadMore}>
            Read More
          </button>
        </div>
      )}

      {visibleCards > 4 && (
        <div className="text-center mt-4">
          <button className="btn btn-secondary me-2" onClick={handleShowLess}>
            Show Less
          </button>
        </div>
      )}
    </div>
  );
};

export default JobDescriptions;
