import React, { useState, useEffect } from "react";
import CertificateTwo from "../assets/certificate-2.jpg";
import CertificateThree from "../assets/certificate-3.jpg";
import CertificateFour from "../assets/certificate-4.jpg";
import CertificateFive from "../assets/certificate-5.jpg";
import CertificateSix from "../assets/certificate-6.jpg";
import CertificateSeven from "../assets/certificate-7.jpg";
import CertificateEight from "../assets/certificate-8.png";
import CertificateNine from "../assets/certificate-9.png";
import CertificateTen from "../assets/certificate-10.png";
import CertificateEleven from "../assets/certificate-11.jpg";
import PMICertificateOne from "../assets/pmi-certificate-1.png";
import PMICertificateTwo from "../assets/pmi-certificate-2.png";

import "./Portfolio.css";

const CertificateModal = ({ image, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      className="modal show d-block"
      tabIndex="-1"
      style={{
        backgroundColor: "rgba(0,0,0,0.7)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1050,
      }}
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body text-center">
            <img
              src={image}
              alt="Certificate"
              className="img-fluid w-100"
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Portfolio = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const certificates = [
    PMICertificateTwo,
    PMICertificateOne,
    CertificateEleven,
    CertificateThree,
    CertificateTwo,
    CertificateFour,
    CertificateEight,
    CertificateNine,
    CertificateTen,
    CertificateSeven,
    CertificateSix,
    CertificateFive,
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id="portfolio" className="section">
      <div className="container">
        <p className="text-center mb-2">
          <span className="bg-primary text-dark px-2">Certificates</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5">
          Highlighting My Certified Expertise
        </h2>
        <div className="row g-4">
          {certificates.map((image, index) => (
            <div
              className="col-sm-6 col-lg-4"
              key={index}
              onClick={() => handleImageClick(image)}
              style={{ cursor: "pointer" }}
            >
              <div className="image-container certificate-img">
                <img
                  src={image}
                  alt={`Certificate ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <CertificateModal image={selectedImage} onClose={handleCloseModal} />
      )}
    </section>
  );
};

export default Portfolio;
