import React, { useState, useEffect } from "react";
import CourseOne from "../assets/course-1.jpg";
import CourseTwo from "../assets/course-2.jpg";
import CourseThree from "../assets/course-3.jpg";
import CourseFour from "../assets/course-4.jpg";
import CourseFive from "../assets/course-5.jpg";
import CourseSix from "../assets/course-6.jpg";
import CertificateOne from "../assets/certificate-1.jpg";

const CourseModal = ({ image, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      className="modal show d-block"
      tabIndex="-1"
      style={{
        backgroundColor: "rgba(0,0,0,0.7)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1050,
      }}
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body text-center">
            <img
              src={image}
              alt="Course"
              className="img-fluid w-100"
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Courses = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const courses = [
    CourseOne,
    CourseTwo,
    CourseThree,
    CourseFour,
    CourseSix,
    CourseFive,
    CertificateOne,
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id="courses" className="section bg-light">
      <div className="container">
        <p className="text-center mb-2">
          <span className="bg-primary text-dark px-2">Courses</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5">
          Educational Journey & Achievements
        </h2>
        <div className="row g-4">
          {courses.map((image, index) => (
            <div
              className="col-sm-6 col-lg-4"
              key={index}
              onClick={() => handleImageClick(image)}
              style={{ cursor: "pointer" }}
            >
              <div className="image-container">
                <img
                  src={image}
                  alt={`Course ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <CourseModal image={selectedImage} onClose={handleCloseModal} />
      )}
    </section>
  );
};

export default Courses;
